
import { computed, defineComponent, onMounted, Ref, ref, watch } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import AuthService from "@/services/AuthService";
import { useRouter } from "vue-router";
import { Modal } from "bootstrap";
import axios from "axios";
import useAlert from "@/composables/Alert";
import Title from "@/components/Title.vue"
import Button from "@/components/Button.vue"

export default defineComponent({
  name: "FiltroLubrificantes",
  components: {
    Title,
    Button
  },
  emits: ["alterarExibicao"],
  setup() {

    const auxModal: any = ref();

    const { showTimeAlert } = useAlert();

    const loading = ref(false);

    const store = useStore();
    const router = useRouter();
    const usuario = AuthService.getUsuario();
    const nivelCargo = usuario.nivelCargo;

    const spanWarning = ref(false);
    const spanWarningEdit = ref(false);

    const prodFinded = ref(false);

    const paginasNumeradas: Ref<any> = ref([]);

    const allLubs: Ref<any> = ref([]);
    const allLubsFiltered: Ref<any> = ref([]);
    const tiposDeProdutosRefEntrada: Ref<any> = ref([]);
    const lubClicked: Ref<any> = ref([]);
    const marcasDeLubrificantes: Ref<any> = ref([]);
    const tipo = ref("");
    const nome = ref("");
    const viscosidade = ref("");
    const lubForm: Ref<any> = ref({
      marca: "",
      produto: "",
      viscosidade: "",
      tipo: "",
      combustivel: "",
      descricao: "",
      imagem: "",
      video: "",
      acea: "",
      api: "",
      ilsac: "",
      norma_bmw: "",
      norma_chrysler: "",
      norma_fiat: "",
      norma_ford: "",
      norma_gm: "",
      norma_jaguar_land_rover: "",
      norma_mercedes: "",
      norma_psa: "",
      norma_renault: "",
      norma_vw: "",
    });

    //FUNCAO PARA PEGAR O TOKEN
    const getToken = async () => {
      return localStorage.getItem("token_usuario");
    };

    async function allLubrificantes() {
      try {
        const res = await axios.get("getAllLubrificantes", {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        });
        allLubs.value = res.data.sort((a, b) => {
          if (a.nome < b.nome) return -1;
          if (a.nome > b.nome) return 1;
          return 0;
        });
        allLubsFiltered.value = allLubs.value;
        numberPages();
        //Aqui eu aproveito esse GET e faço um filtro para obter somente as marcas.
        //Um map desestruturando e obtendo chave e valor que preciso. Um filter com findIndex para exibir somente 1 ocorrencia de cada marca.
        marcasDeLubrificantes.value = res.data
          .map(lubrificante => ({
            nome: lubrificante.nome,
            marca: lubrificante.marca,
          }))
          .filter((lubrificante, index, self) => self.findIndex(lub => lub.marca === lubrificante.marca) === index);
      } catch (err) {
        console.log(err);
      }
    }

    async function getTipoProdutos() {
      try {
        const res = await axios.get("tipoProdutos", {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        });
        tiposDeProdutosRefEntrada.value = res.data.filter(
          product => product.descricao.split(" ")[0] != "Filtro" && product.nome.split("_")[0] != "pneu"
        );
      } catch (err) {
        console.log(err);
      }
    }

    async function addLub() {
      loading.value = true;

      try {
        const res = await axios.post("adicionaLubrificante", lubForm.value, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        });
        if (allLubsFiltered.value.length) {
          allLubsFiltered.value = [];
        }
        allLubrificantes();
        lubForm.value = {
          marca: "",
          produto: "",
          viscosidade: "",
          tipo: "",
          combustivel: "",
          descricao: "",
          imagem: "",
          video: "",
          acea: "",
          api: "",
          ilsac: "",
          norma_bmw: "",
          norma_chrysler: "",
          norma_fiat: "",
          norma_ford: "",
          norma_gm: "",
          norma_jaguar_land_rover: "",
          norma_mercedes: "",
          norma_psa: "",
          norma_renault: "",
          norma_vw: "",
        };
        showTimeAlert("Produto adicionado com sucesso!");
      } catch (err) {
        showTimeAlert("Não foi possivel adicionar o produto.", "error");
      } finally {
        loading.value = false;
      }
    }

    //Função de filtro e busca dos oleos. Monto um objeto com os valores digitados, se nao digitou nenhum, exibo um aviso em tela
    //se digitou os campos, verifico os valores com filter no array principal e retorno, se der ok a pesquisa é exibida e o array preenchido
    //se a pesquisa nao retorna nada o array é verificado se esta vazio, se sim, exibo uma msg de erro na pesquisa para o usuario
    function find() {
      allLubsFiltered.value = [];
      setCurrentPage(1);
      let myLub = {
        nome: lubForm.value.marca,
        viscosidade: lubForm.value.viscosidade,
        tipo: lubForm.value.tipo,
      };
      if (Object.values(myLub).every(val => val === "")) {
        spanWarning.value = true;
        setTimeout(() => {
          spanWarning.value = false;
        }, 5000);
      }
      allLubsFiltered.value = allLubs.value.filter(product => {
        if (myLub.nome.toLowerCase() && product.marca.toLowerCase() !== myLub.nome.toLowerCase()) return false;
        if (myLub.viscosidade.toLowerCase() && product.viscosidade.toLowerCase() !== myLub.viscosidade.toLowerCase()) return false;
        if (myLub.tipo && product.tipo !== myLub.tipo) return false;
        return true;
      });
      if (allLubsFiltered.value.length === 0) {
        return (prodFinded.value = true);
      }
      return (prodFinded.value = false);
    }

    function verifySelectValues(e) {
      e.preventDefault();
      if (!lubForm.value.tipo || !lubForm.value.marca || !lubForm.value.viscosidade || !lubForm.value.produto) {
        spanWarning.value = true;
        return true;
      }
      spanWarning.value = false;
      addLub();
    }

    function clickLub(clicked) {
      lubClicked.value = clicked;
    }

    async function deleteLub(clicked) {
      try {
        const res = await axios.post("deletaLubrificante", clicked, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        });
        allLubsFiltered.value = allLubsFiltered.value.filter(oleo => oleo.id_lubrificante !== clicked.id_lubrificante);
        allLubs.value = [];
        allLubrificantes();
        showTimeAlert("Produto deletado com sucesso!");
      } catch (err) {
        showTimeAlert("Não foi possivel deletar o produto.", "error");
      } finally {
        loading.value = false;
      }
    }

    async function attLub(clicked) {
      let lubFormatPadrao = clicked;
      lubFormatPadrao.combustivel = lubFormatPadrao.combustivel.toLowerCase();

      if (!lubFormatPadrao.tipo || !lubFormatPadrao.marca || !lubFormatPadrao.viscosidade || !lubFormatPadrao.produto) {
        spanWarningEdit.value = true;
        clickLub(lubFormatPadrao);
        return;
      }

      try {
        const res = await axios.post("adicionaLubrificante", lubFormatPadrao, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        });
        allLubrificantes();
        closeModal();
        showTimeAlert("Produto atualizado com sucesso!");
      } catch (err) {
        showTimeAlert("Não foi possivel atualizar o produto.", "error");
      } finally {
        loading.value = false;
      }
    }

    function usePagination() {
      const state = ref({
        currentPage: 1,
        itemsPerPage: 10,
        itemsOnPage: 0,
      });

      const pageCount = computed(() => Math.ceil(allLubsFiltered?.value.length / state.value.itemsPerPage));

      const itemsOnPage = computed(() => {
        const startIndex = (state.value.currentPage - 1) * state.value.itemsPerPage;
        const endIndex = startIndex + state.value.itemsPerPage;
        return allLubsFiltered.value.slice(startIndex, endIndex);
      });

      const setCurrentPage = page => {
        state.value.currentPage = page;
        state.value.itemsOnPage = itemsOnPage.value.length;
      };

      return {
        state,
        setCurrentPage,
        pageCount,
        itemsOnPage,
      };
    }
    const { state, setCurrentPage, pageCount, itemsOnPage } = usePagination();

    const numberPages = () => {
      for (let i = 1; i <= pageCount.value; i++) {
        paginasNumeradas.value = i;
      }
    };

    const openModal = id => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal.value = new Modal(auxElement);
      auxModal.value.show();
    };

    const closeModal = () => {
      auxModal.value.hide();
    };

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
      getToken();
      allLubrificantes();
      getTipoProdutos();
    });

    return {
      spanWarning,
      spanWarningEdit,
      loading,
      allLubs,
      addLub,
      tipo,
      nome,
      viscosidade,
      verifySelectValues,
      tiposDeProdutosRefEntrada,
      find,
      allLubsFiltered,
      openModal,
      clickLub,
      lubClicked,
      deleteLub,
      attLub,
      prodFinded,
      state,
      setCurrentPage,
      pageCount,
      lubForm,
      marcasDeLubrificantes,
      router,
      itemsOnPage,
      numberPages,
      paginasNumeradas,
    };
  },
});
